import React, {FC, useState} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slider,
    TextField,
    Typography
} from "@mui/material";
import {generateRandomPassword, PasswordGenerationVariant} from '../../util/PGPUtil';

import "./GeneratePasswordDialog.scss";
import {SimpleCheckbox} from "../common/SimpleCheckbox";

interface ConfirmDialogProps {
    open: boolean;
    variant: PasswordGenerationVariant;
    onGenerated: (password: string | null) => void;
}

const GeneratePasswordDialog: FC<ConfirmDialogProps> = ({open, variant, onGenerated}) => {
    const [password, setPassword] = useState<string | null>(null);

    const [length, setLength] = useState<number>(variant === "password" ? 16 : 6);
    const [requireNumbers, setRequireNumbers] = useState<boolean>(true);
    const [requireSymbols, setRequireSymbols] = useState<boolean>(true);
    const [excludeNumbers, setExcludeNumbers] = useState<boolean>(false);
    const [excludeSymbols, setExcludeSymbols] = useState<boolean>(false);

    async function generate() {
        // TODO: add other options here
        const newPassword = await generateRandomPassword({
            length,
            requireNumbers,
            requireSymbols,
            excludeNumbers,
            excludeSymbols,
            variant,
        });
        setPassword(newPassword);
    }

    if (password === null) {
        generate();
    }

    return (
        <Dialog
            open={open}
            onClose={() => onGenerated(null)}
        >
            <DialogTitle id="alert-dialog-title">New {variant === "password" ? "Password" : "PIN"} Generator</DialogTitle>
            <DialogContent>
                <Typography id="discrete-slider" gutterBottom>Length - {length}</Typography>
                <Slider
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={variant === "password" ? 8 : 4}
                    max={variant === "password" ? 40 : 10}
                    value={length}
                    onChange={(e, newLength) => {
                        setLength(newLength as number)
                    }}
                />

                {variant === "password" && (<>
                    <SimpleCheckbox
                        label="Include at least one number"
                        checked={requireNumbers}
                        onChange={setRequireNumbers}/>

                    <SimpleCheckbox
                        label="Include at least one symbol"
                        checked={requireSymbols}
                        onChange={setRequireSymbols}/>

                    <SimpleCheckbox
                        label="Exclude numbers"
                        checked={excludeNumbers}
                        onChange={setExcludeNumbers}
                        disabled={requireNumbers}/>

                    <SimpleCheckbox
                        label="Exclude symbols"
                        checked={excludeSymbols}
                        onChange={setExcludeSymbols}
                        disabled={requireSymbols}/>
                </>)}

                <div className="generate-button">
                    <Button onClick={() => generate()} variant="contained" color="secondary">Generate</Button>
                </div>

                <TextField value={password || ""} label={variant === "password" ? "Generated Password" : "Generated PIN"} fullWidth/>

            </DialogContent>

            <DialogActions>
                <Button onClick={() => onGenerated(password)} color="primary" variant="contained"
                        disabled={!password}>OK</Button>
                <Button onClick={() => onGenerated(null)} color="primary" autoFocus>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default GeneratePasswordDialog;
