import "./SimpleGrid.scss";

import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList, ListChildComponentProps} from "react-window";
import React, {ReactNode, RefObject, useRef, useState} from "react";
import {SimpleGridTitle} from "./SimpleGridTitle";
import {SimpleGridRow} from "./SimpleGridRow";
import {AppState} from "../../../AppState";
import {useAppSelector} from "../../../hooks/ReduxHooks";
import {EmailDataSource} from "../../../domain/EmailDataSource";
import InfiniteLoader from "react-window-infinite-loader";
import {EmptyGridNotification} from "../EmptyGridNotification";
import {Argument} from "classnames";

export interface GridRowData<TItem> {
    data?: TItem;

    title?: string;
    name?: string;
    collapsed?: boolean;
}

export interface SimpleGridBaseProps<TItem, TKey> {
    selectedItemSelector: (state: AppState) => TItem[];

    onUpdateSelection: (newSelectedItems: TItem[]) => void;
    onItemClick?: (item: TItem, newSelectedItems: TItem[]) => void;
    onSelectAll?: (selected: boolean) => void;

    rowIdExtractor: (item: TItem) => TKey;

    rowRenderer: (item: TItem) => React.ReactNode;

    rowClassRules: (item: TItem) => Argument;
}

export interface SimpleGridProps<TItem, TKey> extends SimpleGridBaseProps<TItem, TKey> {
    items: TItem[];

    segmentBy?: keyof TItem;

    itemDataSource?: EmailDataSource;

    allItemsLoadedSelector?: (state: AppState) => boolean;

    itemHeight?: number;

    children: React.ReactNode[];

    contextMenu?: (gridRef: RefObject<HTMLDivElement>) => ReactNode;
}

export function SimpleGrid<TItem, TKey>({
                                            itemDataSource,
                                            allItemsLoadedSelector,
                                            items,
                                            selectedItemSelector,
                                            rowRenderer,
                                            rowIdExtractor,
                                            onUpdateSelection,
                                            onSelectAll,
                                            onItemClick,
                                            rowClassRules,
                                            itemHeight,
                                            segmentBy,
                                            children,
                                            contextMenu,
                                        }: SimpleGridProps<TItem, TKey>) {
    const isMobile = useAppSelector(({system}) => system.isMobile);

    // TODO: don't hardcode these!
    const [collapsedSegments, setCollapsedSegments] = useState<string[]>(["This Year", "Last Year", "2023", "2022", "2021", "2020", "2019", "2018", "2017", "2016", "2015", "2014", "2013", "2012", "2011", "2010", "2009", "2008", "2007", "2006", "2005"]);

    const collapsedSegmentsSet = new Set<string>(collapsedSegments);

    let displayRows: GridRowData<TItem>[];

    let visibleRowCount: number;

    if (segmentBy && !isMobile && items.length > 100 && !itemDataSource) {
        displayRows = new Array(items.length);
        let currentSegment = "";
        let j = 0;
        let currentSegmentItemCount = 0;
        for (let i = 0; i < items.length; i++) {
            const segment = items[i][segmentBy];
            if (segment !== currentSegment) {
                if (currentSegment) {
                    const row = displayRows[j - 1];
                    row.title = currentSegmentItemCount ? `${row.name} (${currentSegmentItemCount})` : row.name;
                    currentSegmentItemCount = 0;
                }
                currentSegment = segment as string;
                displayRows[j++] = {name: currentSegment, title: currentSegment, collapsed: collapsedSegmentsSet.has(currentSegment)};
            }
            if (!collapsedSegmentsSet.has(currentSegment)) {
                displayRows[j++] = {data: items[i]};
                // currentSegmentItemCount++;
            } else {
                currentSegmentItemCount++;
            }
        }
        if (currentSegment) {
            const row = displayRows[j - 1];
            row.title = currentSegmentItemCount ? `${row.name} (${currentSegmentItemCount})` : row.name;
        }
        visibleRowCount = j;
    } else {
        displayRows = items.map(i => ({data: i}));
        visibleRowCount = displayRows.length;
    }

    const allItemsLoaded = useAppSelector(state => allItemsLoadedSelector ? allItemsLoadedSelector(state) : true);

    const itemLoadingInProgress = useAppSelector(state => state.itemState.itemLoadingInProgress);

    const gridRef = useRef<HTMLDivElement>(null);

    const rowHeight = itemHeight ?? 30;

    function onRangeSelection(selectedItems: TItem[], endItem: TItem) {
        if (selectedItems.length === 0) return;

        const index1 = items.indexOf(endItem);

        const selectedItemKeys = new Set<TKey>(selectedItems.map(si => rowIdExtractor(si)));

        const index2 = items.indexOf(selectedItems[selectedItems.length - 1]);

        if (index2 < 0) {
            console.error("Last selected item not found in grid items: ", selectedItems[selectedItems.length - 1]);
            return;
        }

        const minIndex = Math.min(index1, index2);
        const maxIndex = Math.max(index1, index2);

        const newSelectedItems = [...selectedItems];
        for (let i = minIndex; i <= maxIndex; i++) {
            if (!selectedItemKeys.has(rowIdExtractor(items[i]))) {
                newSelectedItems.push(items[i]);
            }
        }
        if (newSelectedItems.length > 0) {
            onUpdateSelection(newSelectedItems);
        }
    }

    function onSegmentCollapsed(segment: GridRowData<TItem>, collapsed: boolean) {
        segment.collapsed = collapsed;
        let newCollapsedSegments: string[];
        if (collapsed) {
            newCollapsedSegments = [...collapsedSegments, segment.name!];
        } else {
            newCollapsedSegments = collapsedSegments.filter(s => s !== segment.name);
        }
        setCollapsedSegments(newCollapsedSegments);
    }

    const renderRow = ({index, style}: ListChildComponentProps) =>
        <SimpleGridRow index={index}
                       style={style}
                       rowRenderer={rowRenderer}
                       selectedItemSelector={selectedItemSelector}
                       rowData={displayRows[index]}
                       rowIdExtractor={rowIdExtractor}
                       onUpdateSelection={onUpdateSelection}
                       onRangeSelection={onRangeSelection}
                       onItemClick={onItemClick}
                       rowClassRules={rowClassRules}
                       onSegmentCollapsed={onSegmentCollapsed}
        />;

    let gridElement: React.ReactNode;
    if (itemDataSource && allItemsLoadedSelector) {

        const isItemLoaded = (index: number) => {
            return index < visibleRowCount;
        };

        const loadMoreItems = (startIndex: number, endIndex: number) => {
            return itemDataSource.getNextResultsPage(startIndex, endIndex - startIndex);
        }

        const virtualItemCount = allItemsLoaded ? visibleRowCount : visibleRowCount + 100;

        const renderVirtualGridRow = (props: ListChildComponentProps): JSX.Element => {
            return isItemLoaded(props.index)
                ? renderRow(props)
                : <div className="grid-loading">Loading...</div>;
        };

        gridElement = (
            <InfiniteLoader isItemLoaded={isItemLoaded}
                            itemCount={virtualItemCount}
                            loadMoreItems={loadMoreItems}
                            minimumBatchSize={30}
            >
                {({onItemsRendered, ref}) => (
                    <AutoSizer>
                        {({height, width}: { height: number, width: number }) => (
                            <FixedSizeList onItemsRendered={onItemsRendered}
                                           ref={ref}
                                           key="virtual"
                                           height={height}
                                           width={width}
                                           itemSize={rowHeight}
                                           itemCount={virtualItemCount}
                            >
                                {renderVirtualGridRow}
                            </FixedSizeList>)}
                    </AutoSizer>
                )}
            </InfiniteLoader>
        );
    } else {
        gridElement = (
            <AutoSizer>
                {({height, width}: { height: number, width: number }) => (
                    <FixedSizeList key="list"
                                   height={height}
                                   width={width}
                                   itemSize={rowHeight}
                                   itemCount={visibleRowCount}
                                   overscanCount={10}
                    >
                        {renderRow}
                    </FixedSizeList>
                )}
            </AutoSizer>
        );
    }

    return (<>
        {!isMobile && (
            <SimpleGridTitle
                itemCount={visibleRowCount}
                selectedItemSelector={selectedItemSelector}
                onSelectAll={onSelectAll}
            >
                {children}
            </SimpleGridTitle>
        )}

        <div className="grid-container" ref={gridRef}>
            {visibleRowCount === 0 && !itemLoadingInProgress && allItemsLoaded
                ? <EmptyGridNotification/>
                : gridElement}
        </div>

        {contextMenu && contextMenu(gridRef)}
    </>);
}
